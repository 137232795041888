<template>
  <vs-popup
    id="popupStatusForm"
    classContent="popup-example"
    :title="$t('status.form')"
    :active.sync="popup"
  >
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input
          class="w-full inputx sm-input"
          name="name"
          v-validate="'required'"
          :label-placeholder="$t('status.name')"
          v-model="name"
        ></vs-input
        ><span class="text-danger text-sm" v-if="errors.has('name')">{{
          errors.first("name")
        }}</span>
      </div>
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <vs-input
          class="w-full inputx sm-input"
          name="description"
          :label-placeholder="$t('status.description')"
          v-model="description"
        ></vs-input>
      </div>
      <div class="vx-col w-full mb-2 sm:w-1/2">
        <label class="vs-select--label">{{ $t('company.version') }}</label>
          <v-select
            :options="versiones"
            label="name"
            :clearable="false"
            name="version"
            :data-vv-as="$t('company.version')"
            v-validate="'required'"
            v-model="version_data"
            :placeholder="$t('company.version')"
            autocomplete="off"
          >
            <template slot="option" slot-scope="option">{{ option.name | capitalize }}</template>
          </v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('version')"
          >{{ errors.first('version') }}</span>
      </div>
    </div>
    <vs-divider></vs-divider>
    <vs-row>
      <div class="vx-col w-full mb-2 w-100">
        <vs-list>
          <vs-list-item :title="$t('status.show_driver')" >
            <vs-switch v-model="show_driver">
            </vs-switch>
          </vs-list-item>
        </vs-list>
      </div>
    </vs-row>
    <vs-row>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="12"
        vs-sm="12"
        vs-xs="12"
      >
        <vs-button
          class="mt-6 block offset-3"
          color="primary"
          type="filled"
          :disabled="isSaved"
          @click="setSave"
          >{{ $t("devices.save") }}</vs-button
        >
      </vs-col>
    </vs-row>
  </vs-popup>
</template>

<script>
import HTTP from "@/http-common";
import ErrorNotify from "@/components/errors/Errors";
import i18n from "@/i18n.js";

export default {
  props: {
    status: {
      type: Object,
      required: false,
    },
    popupActivo: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      i18n: i18n,
      name: "",
      description: "",
      versiones: [],
      version_data: null,
      version_id: null,
      isSaved: false,
      show_driver: false
    };
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo;
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", this.status);
          this.$store.dispatch("status/set_status", null);
        }
      },
    },
  },
  watch: {
    status(val) {
      this.clear();
      if (val != null) {
        this.name = val.status;
        this.description = val.description;
        this.version_id = val.version
        this.show_driver = val.show_driver
        if (this.version_id) {
          for (let i=0; i < this.versiones.length; i++) {
            if (this.version_id == this.versiones[i].name) {
              this.version_data = this.versiones[i]
            }
          }
        }
      }
    },
  },
  methods: {
    clear() {
      this.name = null;
      this.description = null;
      this.version_data = null
      this.show_driver = null;
      this.$validator.reset()
    },
    setSave() {
      this.isSaved = true;
      let dataFormated = {
        status: this.name.toUpperCase(),
        description: this.description,
        version: this.version_data.name,
        show_driver: this.show_driver
      };
      if (this.status == null) {
        this.createStatus(dataFormated);
      } else {
        this.updateStatus(dataFormated);
      }
    },
    updateStatus(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = `v1/status/update/${this.status.id}`;
          HTTP.setHeader(true);
          HTTP.put(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("company.update"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.$emit("refreshTable");
              this.isSaved = false;
              this.clear();
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
              this.isSaved = false;
            });
        } else {
          this.isSaved = false;
        }
      });
    },
    createStatus(dataFormated) {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let url = "/v1/status/create";
          HTTP.setHeader(true);
          HTTP.post(url, dataFormated)
            .then(() => {
              this.$vs.notify({
                text: this.$t("company.create"),
                color: "#64cc52",
                iconPack: "feather",
                icon: "icon-check-circle",
              });
              this.popup = false;
              this.$emit("refreshTable");
              this.isSaved = false;
              this.clear();
            })
            .catch((response) => {
              ErrorNotify.formsErrors({
                data: response.response,
                ob: this,
              });
              this.isSaved = false;
            });
        } else {
          this.isSaved = false;
        }
      });
    },
  },
  created() {
    this.versiones.push(
      {
        id: 20,
        name: "V3",
      },
      {
        id: 21,
        name: "V4",
      },
      {
        id: 22,
        name: "V1",
      },
    );
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupStatusForm");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  },
};
</script>

<style>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
