<template lang="pug">
vs-popup(
  id="popupStatusForm"
  classContent="popup-example"
  :title="$t('status.form')"
  :active.sync="popup"
)
  div(
    class="vx-row"
  )
    div(
      class="vx-col sm:w-1/1 w-full mb-2"
    )
      label(
        class="vs-select--label"
      ) {{ $t('users.company') }}
      v-select(
        :options="companies"
        label="name"
        :clearable="false"
        v-model="empresa"
        :placeholder='$t("users.company")'
        autocomplete="off"
      )
        template(
          slot="option"
          slot-scope="option"
        ) {{ option.name | capitalize }}
      span.text-danger.text-sm(
        v-show="errors.has('company')"
      ) {{ errors.first('company') }}
  vs-row
    vs-col(
      vs-type="flex"
      vs-justify="center"
      vs-align="center"
      vs-lg="12"
      vs-sm="12"
      vs-xs="12"
    )
      vs-button.mt-6.block.offset-3(
        color="primary"
        type="filled"
        :disabled="isSaved"
        @click="setSave"
      ) {{$t('devices.save')}}
</template>

<script>
import HTTP from "@/http-common"
import ErrorNotify from "@/components/errors/Errors"//eslint-disable-line
import i18n from "@/i18n.js"
import status from "@/api/status/Status"

export default {
  props: {
    dataStatus:{
      type: Array,
      required: false
    },
    popupActivo: {
      type: Boolean,
      required: false
    },
  },
  data() {
    return {
      i18n: i18n,
      empresa: [],
      company_id: null,
      companies: [],
      isSaved: false
    }
  },
  computed: {
    popup: {
      get() {
        return this.popupActivo
      },
      set(val) {
        if (!val) {
          this.$emit("closePopup", this.status)
        }
      }
    },
  },
  watch: {
    status(val) {
      this.clear()
      if (val != null) {
        this.company_id = val.company_id
        if (this.company_id != null) {
          let index = this.companies.find(element => element.id = this.company_id)
          this.empresa.push(index)
        }
      }
    }
  },
  methods: {
    clear() {
      this.empresa = []
    },
    setSave() {
      this.isSaved = true
      let data = this.dataStatus.map(x => {
        return x.data.id
      })
      if (data.length > 0) {
        this.showDeleteAlert(res => {
          if (res.value == true) {
            let statusData = {
              status: data,
              company_id: this.empresa.id
            }
            status.statusUpdateMultiple(statusData).then(() => {
              this.popup = false
              this.$emit("refreshTableUpdate")
              this.isSaved = false
              this.clear()
            }).catch(() => {
              this.$vs.notify({
                text: this.$t("status.nonEditableStatus"),
                color: "danger",
                iconPack: "feather",
                icon: "icon-x-circle"
              })
            })
          } else {
            this.isSaved = false
          }
        })
      } else {
        this.isSaved = false
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    showDeleteAlert(callback) {
      this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("status.update_status"),
        showCancelButton: true,
        showConfirmButton: true
      }).then(callback)
    },
    getCompany() {
      HTTP.get("/v1/companies/companies/").then(response => {
        response.data.forEach(row => {
          this.companies.push({
            id: row.id,
            name: row.company
          })
        })
      })
    }
  },
  created() {
    this.getCompany()
  },
  beforeDestroy() {
    var elem = document.querySelector("#popupStatusForm")
    if (elem) {
      elem.parentNode.removeChild(elem)
    }
  }
}
</script>

<style>
.toggle-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
