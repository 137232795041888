<template lang="pug">
div
  div(
    v-if="$can('read', 'status')"
    id="div-with-loading-status"
    class="vs-con-loading__container"
  )
    ag-grid-table(
      animateRows=true
      suppressRowClickSelection=true
      :columnDefs="chartConfig.columnDefs"
      :rowData="chartConfig.rowData"
      :gridOptions="gridOptions"
      :sizeTofit="true"
      rowSelection="multiple"
      @RefreshTableData="RefreshTableData"
    )
      template(
        v-if="isActionBar()"
        v-slot:actionBar
      )
        ag-action-bar(
          :is_add="$can('create', 'status')"
          :is_edit="$can('update', 'status')"
          :is_trash="$can('delete', 'status')"
          :is_company="false"
          :is_activate="false"
          :is_deactivate="false"
          :is_exportable="false"
          :is_import="false"
          @add="modalCreateStatus"
          @edit="onBtnEdit"
          @trash="onBtnTrash"
          @IsCompany="IsCompany"
        )
  vue-form(
    ref="FormStatus"
    :popupActivo="popupActivo"
    :status="currentStatus"
    @refreshTable="getStatusAll"
    @closePopup="closePo"
  )
  vue-form-company(
    ref="FormUpdateStatus"
    :popupActivo="popupActivoUpdate"
    :dataStatus="dataStatus"
    @refreshTableUpdate="getStatusAll"
    @closePopup="closePoUpdate"
  )
</template>
<script>
import { validatePermission } from "@/Ability"
import { mapState } from "vuex"
import status from "@/api/status/Status"
import StatusForm from "@/components/status/Form"
import StatusUpdateCompanyForm from "@/components/status/UpdateCompany"
import AgGridTable from "@/components/tableAgGrid/Index"
import ActionsBar from "@/components/tableAgGrid/ActionsBar"
import FieldsStatus from "@/fields/status/fieldsStatus"
import { translateTableFields } from "@/filter"

const NAME = "StatusIndex"

export default {
  name: NAME,
  components: {
    "vue-form": StatusForm,
    "vue-form-company": StatusUpdateCompanyForm,
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar
  },
  data() {
    return {
      popupActivo: false,
      popupActivoUpdate: false,
      currentStatus: null,
      scopes: [],
      dataStatus: null,
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` }
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsStatus.columnDefs),
        rowData: []
      }
    }
  },
  computed: {
    ...mapState("status", ["statusEdit", "statusDelete"])
  },
  watch: {
    statusEdit(val) {
      if (val != null) {
        this.popupActivo = !this.popupActivo
        this.currentStatus = this.statusEdit
      }
    },
    statusDelete(val) {
      if (val != null) {
        this.onBtnTrashInline(val)
      }
    }
  },
  methods: {
    isActionBar() {
      if (
        this.$can("read", "status") ||
        this.$can("create", "status") ||
        this.$can("update", "status") ||
        this.$can("delete", "status")
      ) {
        return true
      } else {
        return false
      }
    },
    RefreshTableData() {
      this.asignColumnDefs()
    },
    asignColumnDefs() {
      this.chartConfig.columnDefs = []
      setTimeout(() => {
        this.chartConfig.columnDefs = translateTableFields(FieldsStatus.columnDefs)
      }, 50)
    },
    IsCompany() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        if (data[0].data.id == null) {
          this.$vs.notify({
            text: this.$t("status.nonEditableStatus"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle"
          })
        } else {
          this.popupActivoUpdate = true
          this.dataStatus = data
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    onBtnTrashInline(data) {
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("status.delete_status"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        showCancelButton: true,
        showConfirmButton: true
      }).then(result => {
        if (result.value) {
          if (data.id == null) {
            this.$vs.notify({
              text: this.$t("status.nonRemovableStatus"),
              color: "danger",
              iconPack: "feather",
              icon: "icon-x-circle"
            })
          } else {
            if (data.id) {
              status.statusDelete(data.id).then(() => {
                this.getStatusAll()
              }).catch(() => {
                  this.$vs.notify({
                    text: this.$t("status.nonRemovableStatus"),
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                  })
                })
            }
          }
        } else {
          this.$store.dispatch("status/set_delete_status", null)
        }
      })
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        if (data[0].data.id == null) {
          this.$vs.notify({
            text: this.$t("status.nonEditableStatus"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle"
          })
        } else {
          this.popupActivo = true
          this.currentStatus = data[0].data
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    onBtnTrash() {
      let data = this.gridOptions.api.getSelectedNodes().map(x => {
        return x.data.id
      })
      if (data.length > 0) {
        this.showDeleteAlert(res => {
          if (res.value == true) {
            let statusData = {
              status: data
            }
            status.statusDeleteMultiple(statusData).then(() => {
              this.getStatusAll()
            }).catch(() => {
              this.$vs.notify({
                text: this.$t("status.nonRemovableStatus"),
                color: "danger",
                iconPack: "feather",
                icon: "icon-x-circle"
              })
            })
          }
        })
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    showDeleteAlert(callback) {
      this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("status.delete_status"),
        showCancelButton: true,
        showConfirmButton: true
      }).then(callback)
    },
    closePo(val) {
      if (val != null) {
        this.$refs.FormStatus.clear()
      }
      this.popupActivo = false
      this.currentStatus = null
    },
    closePoUpdate(val){
      if (val != null) {
        this.$refs.FormUpdateStatus.clear()
      }
      this.popupActivoUpdate = false
      this.currentStatus = null
    },
    modalCreateStatus() {
      this.popupActivo = true
      this.currentStatus = null
    },
    getStatusAll() {
      this.$vs.loading({
        container: "#div-with-loading-status",
        type: "material",
        scale: 1
      })
      status
        .getStatusAll()
        .then(response => {
          this.chartConfig.rowData = response.data
          // setTimeout(() => {
          //   let allColumnIds = []
          //   this.gridOptions.columnApi.getAllColumns().forEach(column => {
          //     allColumnIds.push(column.colId)
          //   })
          //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
          // }, 50)
        })
        .finally(() => {
          this.$vs.loading.close("#div-with-loading-status > .con-vs-loading")
        })
    },
  },
  mounted() {
    this.getStatusAll()
  },
  created() {
    validatePermission(this, "read", "status", "Home")
  }
}
</script>