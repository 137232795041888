<template lang="pug">
  vue-index
</template>


<script>

import CompanyIndex from "@/components/companies/Index"

const NAME = "CompanyIndex"

export default {
  name: NAME,
  components: {
    "vue-index": CompanyIndex
  }
}
</script>