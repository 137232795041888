import HTTP from '@/http-common'
export default {

  getCompany() {
    let request = [
      'v1/companies/companies/'
    ]
    return HTTP.get(...request)
      .then((response) => Promise.resolve(response))
      .catch((error) => Promise.reject(error.response))
  },
  companyDelete(id) {
    HTTP.setHeader(true)
    let request = [
      `v1/companies/delete/${id}`
    ]
    return new Promise((s, r) => {
      HTTP.delete(...request).then(s, r).then(() => { return }, () => { return })
    })
  },
}