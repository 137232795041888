import i18n from "@/i18n.js"

const columnDefs = [
  {
    headerName: `${i18n.t('company.name')}`,
    traslateName: "company.name",
    field: "company",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    headerCheckboxSelection: true,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('oncost.description')}`,
    traslateName: "oncost.description",
    field: "description",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('company.version')}`,
    traslateName: "company.version",
    field: "version",
    sortable: true,
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: true
    }
  },
  {
    headerName: `${i18n.t('company.trajectory')}`,
    traslateName: "company.trajectory",
    field: "show_trajectory_line",
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    cellRenderer: val => {
      if (val.value) {
        return `<i class="fas fa-check"></i>`
      }else{
        return `<i class="fas fa-times"></i>`
      }
    },
  },
  {
    headerName: `${i18n.t('company.icon_cedi')}`,
    traslateName: "company.icon_cedi",
    field: "show_icon",
    filter: true,
    hide: false,
    showKey: true,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    cellRenderer: val => {
      if (val.value) {
        return `<i class="fas fa-check"></i>`
      }else{
        return `<i class="fas fa-times"></i>`
      }
    },
  },
  {
    headerName: `${i18n.t('servicios_tab.actions')}`,
    traslateName: 'servicios_tab.actions',
    sortable: false,
    hide: false,
    filter: false,
    checkboxSelection: false,
    headerCheckboxSelectionFilteredOnly: false,
    headerCheckboxSelection: false,
    filterParams: {
      clearButton: false
    },
    cellRenderer: 'actionscompany'
  }
]


export default {
  columnDefs
}