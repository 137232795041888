<template lang="pug">
div
  div(
    v-if="$can('read', 'company')"
    id="div-with-loading-company"
    class="vs-con-loading__container"
  )
    ag-grid-table(
      animateRows=true
      suppressRowClickSelection=true
      :columnDefs="chartConfig.columnDefs"
      :rowData="chartConfig.rowData"
      :gridOptions="gridOptions"
      :sizeTofit="true"
      rowSelection="single"
      @RefreshTableData="RefreshTableData"
    )
      template(
        v-if="isActionBar()"
        v-slot:actionBar
      )
        ag-action-bar(
          :is_add="$can('create', 'company')"
          :is_edit="$can('update', 'company')"
          :is_trash="$can('delete', 'company')"
          :is_activate="false"
          :is_deactivate="false"
          :is_exportable="false"
          :is_import="false"
          @add="modalCreateCompany"
          @edit="onBtnEdit"
          @trash="onBtnTrash"
        )
  vue-form(
    ref="FormCompany"
    :popupActivo="popupActivo"
    :company="currentCompany"
    @refreshTable="getCompany"
    @closePopup="closePo"
  )

</template>
<script>
import { validatePermission } from "@/Ability"
import { mapState } from "vuex"
import company from "@/api/company/company"
import CompanyForm from "@/components/companies/Form"
import AgGridTable from "@/components/tableAgGrid/Index"
import ActionsBar from "@/components/tableAgGrid/ActionsBar"
import FieldsCompany from "@/fields/company/fieldsCompany"
import { translateTableFields } from "@/filter"

import editor from '@tinymce/tinymce-vue'

const NAME = "CompanyIndex"

export default {
  name: NAME,
  components: {
    "vue-form": CompanyForm,
    "ag-grid-table": AgGridTable,
    "ag-action-bar": ActionsBar,
    "tiny-mce": editor
  },
  data() {
    return {
      popupActivo: false,
      currentCompany: null,
      scopes: [],
      gridOptions: {
        localeText: { noRowsToShow: `${this.$t("show_not_data")}` }
      },
      chartConfig: {
        columnDefs: translateTableFields(FieldsCompany.columnDefs),
        rowData: []
      }
    }
  },
  computed: {
    ...mapState("company", ["companyEdit", "companyDelete"])
  },
  watch: {
    companyEdit(val) {
      if (val != null) {
        this.popupActivo = !this.popupActivo
        this.currentCompany = this.companyEdit
      }
    },
    companyDelete(val) {
      if (val != null) {
        this.onBtnTrashInline(val)
      }
    }
  },
  methods: {
    isActionBar() {
      if (
        this.$can("read", "company") ||
        this.$can("create", "company") ||
        this.$can("update", "company") ||
        this.$can("delete", "company")
      ) {
        return true
      } else {
        return false
      }
    },
    RefreshTableData() {
      this.asignColumnDefs()
    },
    asignColumnDefs() {
      this.chartConfig.columnDefs = []
      setTimeout(() => {
        this.chartConfig.columnDefs = translateTableFields(FieldsCompany.columnDefs)
      }, 50)
    },
    onBtnTrashInline(data) {
      this.$swal({
        position: "center",
        type: "info",
        title: this.$t("company.delete_company"),
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        showCancelButton: true,
        showConfirmButton: true
      }).then(result => {
        if (result.value) {
          if (data.id == null) {
            this.$vs.notify({
              text: this.$t("company.nonRemovableCompany"),
              color: "danger",
              iconPack: "feather",
              icon: "icon-x-circle"
            })
          } else {
            if (data.id) {
              company.companyDelete(data.id).then(() => {
                this.getCompany()
              }).catch(() => {
                  this.$vs.notify({
                    text: this.$t("company.nonRemovableCompany"),
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                  })
                })
            }
          }
        } else {
          this.$store.dispatch("company/set_delete_company", null)
        }
      })
    },
    onBtnEdit() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        if (data[0].data.id == null) {
          this.$vs.notify({
            text: this.$t("company.nonEditableCompany"),
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle"
          })
        } else {
          this.popupActivo = true
          this.currentCompany = data[0].data
        }
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    onBtnTrash() {
      let data = this.gridOptions.api.getSelectedNodes()
      if (data.length > 0) {
        this.showDeleteAlert(res => {
          if (res.value == true) {
            if (data[0].data.id == null) {
              this.$vs.notify({
                text: this.$t("company.nonRemovableCompany"),
                color: "danger",
                iconPack: "feather",
                icon: "icon-x-circle"
              })
            } else {
              if (data[0].data.id) {
                company.companyDelete(data[0].data.id).then(() => {
                  this.getCompany()
                }).catch(() => {
                  this.$vs.notify({
                    text: this.$t("company.nonRemovableCompany"),
                    color: "danger",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                  })
                })
              }
            }
          }
        })
      } else {
        this.$vs.notify({
          text: this.$t("vehicles.selecction"),
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle"
        })
      }
    },
    showDeleteAlert(callback) {
      this.$swal({
        position: "center",
        type: "warning",
        confirmButtonColor: "#FDD402",
        cancelButtonColor: "#ea5455",
        title: this.$t("company.delete_company"),
        showCancelButton: true,
        showConfirmButton: true
      }).then(callback)
    },
    closePo(val) {
      if (val != null) {
        this.$refs.FormCompany.clear()
      }
      this.popupActivo = false
      this.currentCompany = null
    },
    modalCreateCompany() {
      this.popupActivo = true
      this.currentCompany = null
    },
    getCompany() {
      this.$vs.loading({
        container: "#div-with-loading-company",
        type: "material",
        scale: 1
      })
      company
        .getCompany()
        .then(response => {
          this.chartConfig.rowData = response.data
          // setTimeout(() => {
          //   let allColumnIds = []
          //   this.gridOptions.columnApi.getAllColumns().forEach(column => {
          //     allColumnIds.push(column.colId)
          //   })
          //   this.gridOptions.columnApi.autoSizeColumns(allColumnIds)
          // }, 50)
        })
        .finally(() => {
          this.$vs.loading.close("#div-with-loading-company > .con-vs-loading")
        })
    },
  },
  mounted() {
    this.getCompany()
  },
  created() {
    validatePermission(this, "read", "company", "Home")
  }
}
</script>