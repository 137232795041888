<template lang="pug">
  vue-index
</template>


<script>

import StatusIndex from "@/components/status/Index"

const NAME = "StatusIndex"

export default {
  name: NAME,
  components: {
    "vue-index": StatusIndex
  }
}
</script>